import { render, staticRenderFns } from "./SearchResultComponent.vue?vue&type=template&id=883f1522&scoped=true&"
import script from "./SearchResultComponent.vue?vue&type=script&lang=ts&"
export * from "./SearchResultComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/search-result/SearchResultComponent.scss?vue&type=style&index=0&id=883f1522&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "883f1522",
  null
  
)

export default component.exports